import React, { Component } from "react";

import { Stack, Button } from "@mui/material";

import _ from "lodash";
import { shuffle, configureValues, arrangeModels } from "models/Game";
import ESGSort from "components/ESGSort";

const round_template = {
  name: "...",
  matchValue: 0,
  current_round: 0,
  total_rounds: 1,
  current_state: [],
};

// coordinates loading the game and presenting it
class Play extends Component {
  constructor({ game, round, onSubmit }) {
    super();
    this.onSubmit = onSubmit;
    this.state = {
      game: game, // wait for game to load
      round: round,
      round_config: { ...round_template },
    };
  }

  componentDidMount() {
    this.initializeGameRound();
  }

  // receive changes from the list of sliders and apply them to state
  handleDataChange = (data) => {
    var rc = this.state.round_config;
    if (!rc) return;
    rc.current_state = [...data];
    this.setState({ round_config: rc });
  };

  set_profile = (profile) => this.setState({ profile: profile });
  set_submission = (newSubmission, nextRound) => {
    this.onSubmit(newSubmission);
  };

  initializeGameRound = () => {
    const { game, round, model_map } = this.state;

    if (!game) return console.warn("Failed to initialize game round. No game is mounted");

    let name = game.name || "unnamed game";
    let all_game_rounds = game.rounds;

    let current_game_round = all_game_rounds[round];
    if (!current_game_round) console.warn("Failed to initialize game round. No rounds to select");

    let data = shuffle(
      configureValues(
        current_game_round.matchValue,
        arrangeModels({
          models: current_game_round.models,
          model_map: model_map,
        })
      )
    );

    let rc = {
      name: name,
      matchValue: current_game_round.matchValue,
      current_round: round,
      total_rounds: all_game_rounds.length,
      initial_state: [...data],
      current_state: _.cloneDeep([...data]),
    };

    this.setState({ round_config: rc });
  };

  render() {
    const { round_config } = this.state;
    const updateData = (data) => this.handleDataChange(data);
    // const randomize = () => this.initializeGameRound();
    const setupGame = () => {
      let data = configureValues(round_config.matchValue, round_config.models);
      updateData(data);
    };

    const handle_submit = () => {
      this.set_submission(
        {
          initial_state: round_config.initial_state,
          final_state: round_config.current_state,
        },
        setupGame
      );
    };

    // Actions to perform on the current game round
    const ActionsFooter = () => {
      return (
        <Stack gap={1}>
          {/* <Button variant="outlined" onClick={randomize}>
            Randomize
          </Button> */}
          <Button variant="contained" onClick={handle_submit}>
            Next
          </Button>
        </Stack>
      );
    };

    // Render the game scene
    return (
      <>
        {/* Rows of models w/ sliders */}
        <div className="row p-4">
          <p className="text-success py-2">
            <h6>Highest Priority</h6>
          </p>
          <ESGSort
            data={round_config.current_state}
            total={round_config.matchValue}
            onReorder={updateData}
          />
          <p className="text-danger py-1">
            <h6>Lowest Priority</h6>
          </p>
        </div>
        <ActionsFooter />
      </>
    );

    // return (
    //   <>

    //     <SortGameView
    //       config={round_config}
    //       setup={this.initializeGameRound}
    //       onChange={this.handleDataChange}
    //       onSubmit={this.set_submission}
    //     />
    //   </>
    // );
  }
}

export default Play;
