import React from "react";
import { Badge } from "react-bootstrap";
import ESGRow from "./ESGRow";

function ESGList(props) {
  const { data, total, shuffle } = props;

  const setValueAtIndex = (newValue, index) => {
    const newState = data;
    const model = newState[index];
    model.value = newValue;
    newState[index] = model;
    props.onChange(data);
  };

  const shuffledData = React.useMemo(() => {
    let newData = structuredClone(data); // Create a new array to avoid mutating the original data
    if (shuffle) {
      newData.sort(() => Math.random() - 0.5);
    } else {
      newData.sort((a, b) => (a.name > b.name ? 1 : -1)); // Ensure consistent sort result
    }
    return newData;
  }, [data, shuffle]);

  return data === null ? (
    <Badge />
  ) : (
    <ol className="list-group">
      {shuffledData.map((item, index) => (
        <li key={item.name} className="list-group-item">
          <ESGRow
            item={item}
            max={total}
            onChange={setValueAtIndex}
            config={{
              showValues: true,
              showSlider: true,
              index: index,
              total: total,
            }}
          />
        </li>
      ))}
    </ol>
  );
}

export default ESGList;
